import { clsx } from 'clsx';
import Link from 'next/link';
import React from 'react';
import type { ILinkProps } from '../../types/types';

type Options = {
  payload?: string;
  IconBefore?: JSX.Element;
  IconAfter?: JSX.Element;
};

export function TransparentButton({
  payload,
  IconBefore,
  IconAfter,
  className,
  ...props
}: Options & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'>) {
  return (
    <button
      className={clsx(
        'group flex items-center justify-center rounded-md py-3 ',
        'focus:bg-green-100 focus:shadow-[0_0_0_1px_white,0_0_0_3px_theme(colors.green.400)]',

        'button-hover-color-transition hover:bg-green-100 disabled:bg-white',
        'text-neutral-700 hover:text-green-900 focus:text-green-900 disabled:text-neutral-400',
        'fill-neutral-700 hover:fill-green-600 focus:fill-green-600 disabled:fill-neutral-400',

        IconBefore || (!payload && IconAfter) ? 'pl-3' : 'pl-4',
        IconAfter || (!payload && IconBefore) ? 'pr-3' : 'pr-4',
        className
      )}
      {...props}
    >
      <Children
        IconBefore={IconBefore}
        IconAfter={IconAfter}
        payload={payload}
      />
    </button>
  );
}

export function TransparentButtonLink({
  payload,
  IconBefore,
  IconAfter,
  className,
  ...props
}: Options & ILinkProps) {
  return (
    <Link
      {...props}
      className={clsx(
        'group flex items-center justify-center rounded-md py-3 ',
        'focus:bg-green-100 focus:shadow-[0_0_0_1px_white,0_0_0_3px_theme(colors.green.400)]',

        'button-hover-color-transition hover:bg-green-100 disabled:bg-white',
        'text-neutral-700 hover:text-green-900 focus:text-green-900 disabled:text-neutral-400',
        'fill-neutral-700 hover:fill-green-600 focus:fill-green-600 disabled:fill-neutral-400',

        IconBefore || (!payload && IconAfter) ? 'pl-3' : 'pl-4',
        IconAfter || (!payload && IconBefore) ? 'pr-3' : 'pr-4',
        className
      )}
    >
      <Children
        IconBefore={IconBefore}
        IconAfter={IconAfter}
        payload={payload}
      />
    </Link>
  );
}

const Children = ({
  IconBefore,
  IconAfter,
  payload,
}: Pick<Options, 'IconBefore' | 'payload' | 'IconAfter'>) => (
  <div className={clsx('flex items-center')}>
    {IconBefore}
    {payload && (
      <div
        className={clsx(
          'payload',
          'text-label-2-medium',
          IconBefore && 'ml-1',
          IconAfter && 'mr-1'
        )}
      >
        {payload}
      </div>
    )}
    {IconAfter}
  </div>
);
