/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { apiUrl, strapiApiUrl, worldDomainName } from 'src/config';
import type { AddHumanRecord } from 'src/types/human';
import type { TidBitsV3Payload } from 'src/types/types';
import { checkDomainNames } from './checkDomainNames';
import { paramsToObject } from './paramsToObject';
import prepareSearchFilters from './prepareSearchFilters';

export type NewsletterQuery = {
  page: number;
  size: number;
  isMain?: boolean;
};

const removeEmptyValueInObject = (object: {
  [key: string]: string | boolean;
}) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, value]) => value != '')
  );
};

export const calls = {
  searchForAllCountries: () => ({
    url: `/api/search-all-countries`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  searchForAllCountriesStaticProps: () => ({
    url: `https://${worldDomainName}/countries/`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  searchByFilters: (
    firstName: string,
    lastName: string,
    country: string,
    page?: string,
    pageSize?: string,
    filters?: any,
    domainName?: string
  ) => {
    const newFilters = prepareSearchFilters(filters);

    const url: string | null =
      `/api/search-by-filters?firstName=${firstName}&lastName=${lastName}&country=${country}${
        page && `&page=${page}`
      }${pageSize && `&pageSize=${pageSize}`}${
        newFilters ? `&${new URLSearchParams(newFilters)?.toString()}` : ''
      }`.includes('undefined')
        ? null
        : `/api/search-by-filters?firstName=${firstName}&lastName=${lastName}&country=${country}${
            page && `&page=${page}`
          }${pageSize && `&pageSize=${pageSize}`}${
            newFilters ? `&${new URLSearchParams(newFilters)?.toString()}` : ''
          }`;

    if (url) {
      return {
        url: url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        data: {
          domainName: checkDomainNames(domainName as string),
        },
        // I know this is a POST method, but it gets data so we need to add a retry here as well
        'axios-retry': {
          retries: 2,
        },
      };
    }
  },
  searchByHumanId: (id: string, country: string, domainName: string) => ({
    url: `https://${checkDomainNames(
      domainName
    )}/api/v2/people?id=${id}&country=${country}`,
    headers: {
      'Cache-Control': 's-maxage=0, stale-while-revalidate, no-store',
    },
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  getTidBits: (params: Record<string, any>, domainName: string) => ({
    url: `https://${checkDomainNames(
      domainName
    )}/api/v1/getTidbits?${new URLSearchParams(params)?.toString()}`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
    timeout: 10_000,
  }),
  getTidBitsV2: (params: Record<string, any>, domainName: string) => ({
    url: `https://${checkDomainNames(
      domainName
    )}/api/v2/getTidbits?${new URLSearchParams(params)?.toString()}`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
    timeout: 10_000,
  }),
  getTidBitsV3: (body: TidBitsV3Payload, domainName: string) => ({
    url: `https://${checkDomainNames(domainName)}/api/v3/getTidbits`,
    data: body,
    method: 'POST',
    'axios-retry': {
      retries: 2,
    },
    timeout: 10_000,
  }),
  searchByHumanIdClientSide: (
    id: string,
    countryIso3: string,
    domainName: string
  ) => ({
    url: `/api/search-by-human-id?id=${id}&countryIso3=${countryIso3}&domain=${checkDomainNames(
      domainName
    )}`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  searchByFiltersServerProps: (
    firstName: string,
    lastName: string,
    country: string,
    page?: string,
    pageSize?: number,
    filters?: any,
    domainName?: string
  ) => {
    const urlParamsFilters = new URLSearchParams(filters);
    const entriesFilters = urlParamsFilters.entries();
    const objectFilters = paramsToObject(entriesFilters);

    const newFilters = {
      first_name2: objectFilters?.first_name2
        ? (objectFilters?.first_name2 as string)
        : '',
      maiden_name: objectFilters?.maiden_name
        ? (objectFilters?.maiden_name as string)
        : '',
      birth_day: objectFilters?.birth_day
        ? (objectFilters?.birth_day as string)
        : '',
      birth_month: objectFilters?.birth_month
        ? (objectFilters?.birth_month as string)
        : '',
      birth_year: objectFilters?.birth_year
        ? (objectFilters?.birth_year as string)
        : '',
      birth_country: objectFilters?.birth_country
        ? (objectFilters?.birth_country as string)
        : '',
      birth_region: objectFilters?.birth_region
        ? (objectFilters?.birth_region as string)
        : '',
      birth_city: objectFilters?.birth_city
        ? (objectFilters?.birth_city as string)
        : '',
      death_day: objectFilters?.death_day
        ? (objectFilters?.death_day as string)
        : '',
      death_month: objectFilters?.death_month
        ? (objectFilters?.death_month as string)
        : '',
      death_year: objectFilters?.death_year
        ? (objectFilters?.death_year as string)
        : '',
      death_country: objectFilters?.death_country
        ? (objectFilters?.death_country as string)
        : '',
      death_region: objectFilters?.death_region
        ? (objectFilters?.death_region as string)
        : '',
      death_city: objectFilters?.death_city
        ? (objectFilters?.death_city as string)
        : '',
      sex: objectFilters?.sex?.length > 0 ? objectFilters?.sex : 'both',
      sort: objectFilters?.sort ? objectFilters?.sort : '',
      alive: objectFilters?.alive?.length > 0 ? objectFilters?.alive : 'yes',
      birth_margin: objectFilters?.birth_margin
        ? objectFilters?.birth_margin
        : '',
      death_margin: objectFilters?.death_margin
        ? objectFilters?.death_margin
        : '',
    };

    const domain = `https://${checkDomainNames(domainName as string)}/API`;

    const url =
      `${domain}/v2/people?first_name1=${firstName}&last_name1=${lastName}&country=${country}&page_size=${pageSize}&page=${page}${
        newFilters
          ? `&${new URLSearchParams( // @ts-ignore
              removeEmptyValueInObject(newFilters)
            )?.toString()}`
          : ''
      }`.includes('undefined')
        ? null
        : `${domain}/v2/people?first_name1=${firstName}&last_name1=${lastName}&country=${country}&page_size=${pageSize}&page=${page}${
            newFilters
              ? `&${new URLSearchParams( // @ts-ignore
                  removeEmptyValueInObject(newFilters)
                )?.toString()}`
              : ''
          }`;

    if (url) {
      return {
        url: encodeURI(url),
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        'axios-retry': {
          retries: 2,
        },
      };
    }
  },
  getCountry: (countryIso3: string, domainName: string) => ({
    url: `/api/get-country-data?countryIso3=${countryIso3}&domainName=${checkDomainNames(
      domainName
    )}`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  getCountryStats: (countryIso3: string, domainName: string) => ({
    url: `/api/get-country-stats?countryIso3=${countryIso3}&domainName=${checkDomainNames(
      domainName
    )}`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),

  getCountryStaticProps: (countryIso3: string, domainName: string) => ({
    url: `https://${checkDomainNames(domainName)}/countries/${countryIso3}/`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  getNameTypeData: (nameType: string, name: string) => ({
    url: `/api/name-data?nameType=${nameType}&name=${name}`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  getNameTypeDataServerProps: (nameType: string, name: string) => ({
    url: `https://${worldDomainName}/api/v2/getNameData?name_type=${nameType}&name=${name}`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  sendMail: (email: string, text: string, name: string) => ({
    url: `/api/send-email`,
    method: 'POST',
    data: {
      email: email,
      text: text,
      name: name,
    },
  }),
  suggestDeletionEmail: (
    humanId: string,
    reason: string,
    usernameOrIp: string
  ) => ({
    url: `/api/suggest-deletion-email`,
    method: 'POST',
    data: {
      humanId: humanId,
      reason: reason,
      usernameOrIp: usernameOrIp,
    },
  }),
  reportRecordEmail: (
    link: string,
    reason: string,
    recipientEmail?: string,
    comment?: string,
    usernameOrIp?: string | null
  ) => ({
    url: `/api/report-record-email`,
    method: 'POST',
    data: {
      link: link,
      reason: reason,
      recipientEmail: recipientEmail,
      comment: comment,
      usernameOrIp: usernameOrIp,
    },
  }),
  sendEmailWithPNG: (
    email: string,
    html: string,
    name: string,
    png: string,
    figureName: string,
    subject: string
  ) => ({
    url: `/api/send-email-with-png`,
    method: 'POST',
    data: {
      email: email,
      html: html,
      name: name,
      png: png || '',
      figureName: figureName,
      subject: subject,
    },
  }),
  unsubscribeEmail: (email: string) => ({
    url: `/api/unsubscribe-email`,
    method: 'POST',
    data: {
      email: email,
    },
  }),
  sendUnsubscribeEmail: (email: string) => ({
    url: `/api/send-unsubscribe-email`,
    method: 'POST',
    data: {
      email: email,
    },
  }),
  searchForUnsubscribedEmail: (email: string) => ({
    url: `/api/search-for-unsubscribed-emails`,
    method: 'POST',
    data: {
      email: email,
    },
  }),
  deleteEmailFromUnsubscribedGroup: (email: string) => ({
    url: `/api/delete-email-from-unsubscribed-group`,
    method: 'DELETE',
    data: {
      email: email,
    },
  }),
  similarFirstNames: (countryIso3: string, firstName: string) => ({
    url: `/api/similar-first-names?countryIso3=${countryIso3}&firstName=${firstName}`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  similarFirstNamesServerProps: (countryIso3: string, firstName: string) => ({
    url: `${apiUrl}/v1/getSimilarFirstNames?country=${countryIso3}&first_name=${firstName}`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  similarLastNames: (countryIso3: string, lastName: string) => ({
    url: `/api/similar-last-names?countryIso3=${countryIso3}&lastName=${lastName}`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  similarLastNamesServerProps: (countryIso3: string, lastName: string) => ({
    url: `${apiUrl}/v1/getSimilarLastNames?country=${countryIso3}&last_name=${lastName}`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  getHumanHistory: (id: string, countryIso3: string, domain: string) => ({
    url: `/api/get-human-history?id=${id}&countryIso3=${countryIso3}&domain=${checkDomainNames(
      domain
    )}`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  getHumanHistoryServerProps: (
    id: string,
    countryIso3: string,
    domain: string
  ) => ({
    url: `https://${checkDomainNames(
      domain
    )}/API/v1/changeHistoryForPerson?id=${encodeURI(
      id as string
    )}&country=${countryIso3}`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  signUp: (email: string, username: string, password: string) => ({
    url: `/api/sign-up`,
    method: 'POST',
    data: {
      email: email,
      username: username,
      password: password,
    },
  }),
  signUpWithNoEmail: (username: string, password: string) => ({
    url: `/api/sign-up-with-no-email`,
    method: 'POST',
    data: {
      username: username,
      password: password,
    },
  }),
  login: (username: string, password: string) => ({
    url: `/api/login`,
    method: 'POST',
    data: {
      username: username,
      password: password,
    },
  }),
  loginWithGoogle: (accessToken: string) => ({
    url: `${strapiApiUrl}/auth/google/callback?access_token=${accessToken}`,
    method: 'GET',
  }),
  checkUserToken: (userToken: string) => ({
    url: `${strapiApiUrl}/token-validator-client`,
    method: 'POST',
    data: {
      user_token: userToken,
    },
  }),
  getUser: (userToken: string) => ({
    url: `/api/get-user`,
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
    'axios-retry': {
      retries: 2,
    },
  }),
  getUserServerSide: (userToken: string) => ({
    url: `${strapiApiUrl}/users/me`,
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
    'axios-retry': {
      retries: 2,
    },
  }),
  forgotPassword: (email: string) => ({
    url: `/api/forgot-password`,
    method: 'POST',
    data: {
      email: email,
    },
  }),
  resetPassword: (
    password: string,
    passwordConfirmation: string,
    code: string
  ) => ({
    url: `/api/reset-password`,
    method: 'POST',
    data: {
      password: password,
      passwordConfirmation: passwordConfirmation,
      code: code,
    },
  }),
  changePassword: (
    userToken: string,
    password: string,
    passwordConfirmation: string,
    currentPassword: string
  ) => ({
    url: `${strapiApiUrl}/auth/change-password`,
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
    data: {
      password: password,
      passwordConfirmation: passwordConfirmation,
      currentPassword: currentPassword,
    },
  }),
  deleteAccount: (userToken: string, userId: string, password: string) => ({
    url: `/api/delete-account`,
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
    data: {
      userId: userId,
      password: password,
    },
  }),
  emailConfirmation: (code: string) => ({
    url: `/api/email-confirmation?code=${code}`,
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
  }),
  sendEmailConfirmation: (email: string) => ({
    url: `/api/send-email-confirmation`,
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    data: {
      email: email,
    },
  }),
  updateConfirmation: (
    userToken: string,
    userId: string,
    confirmed: boolean
  ) => ({
    url: `${strapiApiUrl}/users/${userId}`,
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
    data: {
      confirmed: confirmed,
    },
  }),
  updateEmail: (userToken: string, userId: string, email: string) => ({
    url: `${strapiApiUrl}/users/${userId}`,
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
    data: {
      email: email,
    },
  }),
  updateCountry: (userToken: string, userId: string, country: string) => ({
    url: `${strapiApiUrl}/users/${userId}`,
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
    data: {
      country: country,
    },
  }),
  addHumanRecord: (humanRecord: AddHumanRecord, domainName: string) => ({
    url: `/api/add-human-record`,
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    data: {
      humanRecord: humanRecord,
      domainName: checkDomainNames(domainName),
    },
  }),
  deleteHumanRecord: (
    humanId: string,
    countryIso3: string,
    domainName: string
  ) => ({
    url: `/api/delete-human-record`,
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    data: {
      humanId: humanId,
      countryIso3: countryIso3,
      domainName: domainName,
    },
  }),
  fetchBlogPosts: () => ({
    url: `${strapiApiUrl}/articles`,
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
    params: {
      populate: '*',
      sort: 'publishedAt:desc',
    },
    'axios-retry': {
      retries: 2,
    },
  }),
  fetchBlogPost: (id: string) => ({
    url: `${strapiApiUrl}/articles/${id}?populate=*`,
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
    'axios-retry': {
      retries: 2,
    },
  }),
  fetchNewsletterPosts: (query: NewsletterQuery) => ({
    url: `${strapiApiUrl}/newsletters`,
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
    params: {
      populate: '*',
      sort: 'publishedAt:desc',
      'pagination[page]': query.page,
      'pagination[pageSize]': query.size,
      ...(query.isMain && { 'filters[isMainArticle][$eq]': query.isMain }),
    },
    'axios-retry': {
      retries: 2,
    },
  }),
  fetchNewsletterPost: (id: string) => ({
    url: `${strapiApiUrl}/newsletters/${id}?populate=*`,
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
    'axios-retry': {
      retries: 2,
    },
  }),
  getAllCountriesWithRegionOrState: () => ({
    url: `/api/get-all-countries-with-region-or-state`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  getAllCountriesWithRegionOrStateStaticProps: () => ({
    url: `https://${worldDomainName}/locations/`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  getLocation: (countryIso3: string, domainName: string) => ({
    url: `/api/get-location?countryIso3=${countryIso3}&domainName=${checkDomainNames(
      domainName
    )}`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  getLocationPath: (countryIso3: string, path: string, domainName: string) => ({
    url: `/api/get-location-path?countryIso3=${countryIso3}&path=${path}&domainName=${checkDomainNames(
      domainName
    )}`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  getBase64Logo: () => ({
    url: `/api/get-base-64-logo`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
  addBookmark: (
    record_id: string,
    country_iso_3: string,
    user_token: string,
    user_id: string,
    tagOption: 'me' | 'friends' | 'family',
    domain: string
  ) => ({
    url: '/api/post-bookmark',
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    data: {
      humanRecord: {
        record_id: record_id,
        country_iso_3: country_iso_3,
        tag: tagOption,
        user_id: user_id,
        user_token: user_token,
      },
      domain: checkDomainNames(domain),
    },
  }),
  updateBookmark: (
    record_id: string,
    country_iso_3: string,
    user_token: string,
    user_id: string,
    tagOption: 'me' | 'friends' | 'family',
    domain: string
  ) => ({
    url: '/api/put-bookmark',
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    data: {
      humanRecord: {
        record_id: record_id,
        country_iso_3: country_iso_3,
        tag: tagOption,
        user_id: user_id,
        user_token: user_token,
      },
      domain: checkDomainNames(domain),
    },
  }),
  deleteBookmark: (
    record_id: string,
    country_iso_3: string,
    user_token: string,
    user_id: string,
    tagOption: 'me' | 'friends' | 'family',
    domain: string
  ) => ({
    url: '/api/delete-bookmark',
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    data: {
      humanRecord: {
        record_id: record_id,
        country_iso_3: country_iso_3,
        tag: tagOption,
        tag_action: 'delete',
        user_id: user_id,
        user_token: user_token,
      },
      domain: checkDomainNames(domain),
    },
  }),
  getBookmarks: (user_id: string, user_token: string, domain: string) => ({
    url: `/api/get-bookmarks?user_id=${user_id}&user_token=${user_token}&domain=${checkDomainNames(
      domain
    )}`,
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
    'axios-retry': {
      retries: 2,
    },
  }),
  getCreatedRecords: (
    userId: string,
    user_token: string,
    domainName: string
  ) => ({
    url: `/api/get-created-records?userId=${userId}&user_token=${user_token}&domainName=${checkDomainNames(
      domainName
    )}`,
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
    'axios-retry': {
      retries: 2,
    },
  }),
  getEditHistory: (userId: string, user_token: string, domainName: string) => ({
    url: `/api/get-edit-history?userId=${userId}&user_token=${user_token}&domainName=${checkDomainNames(
      domainName
    )}`,
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
    'axios-retry': {
      retries: 2,
    },
  }),
  updateNrOfRows: (
    userToken: string,
    userId: string,
    defaultNrOfResults: string
  ) => ({
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    },
    url: `${strapiApiUrl}/users/${userId}`,
    data: {
      defaultNrOfResults: defaultNrOfResults,
    },
  }),

  getTopNamesData: (
    countryIso3?: string,
    domain?: string,
    page = '1',
    pageSize = '10'
  ) => ({
    url: `/api/get-top-names-data?country_iso3=${countryIso3}&page=${page}&page_size=${pageSize}&domain=${checkDomainNames(
      domain || worldDomainName
    )}`,
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
    'axios-retry': {
      retries: 2,
    },
  }),

  getTopNamesDataStaticProps: (
    countryIso3?: string,
    domain?: string,
    page = '1',
    pageSize = '2'
  ) => ({
    url: `https://${checkDomainNames(
      domain || worldDomainName
    )}/api/v1/topNames/${countryIso3}?page=${page}&page_size=${pageSize}`,
    method: 'GET',
    'axios-retry': {
      retries: 2,
    },
  }),
};
