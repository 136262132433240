import Link from 'next/link';
import React from 'react';
import type { ILinkProps } from '../../types/types';
import { cn } from '../../utils/cn';

type Options = {
  payload: string;
};

export function GreenButton(
  options: Options &
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'>
) {
  return (
    <button
      className={cn(
        'rounded-md bg-green-600 px-4 py-3 hover:bg-green-700 button-hover-color-transition focus:bg-green-700 focus:shadow-[0_0_0_1px_white,0_0_0_3px_theme(colors.green.400)]',
        options.className
      )}
    >
      <div className="text-label-2-semibold text-white">{options.payload}</div>
    </button>
  );
}

export function GreenButtonLink({
  className,
  payload,
  ...props
}: Options & ILinkProps) {
  return (
    <Link
      {...props}
      className={cn(
        'rounded-md bg-green-600 px-4 py-3 hover:bg-green-700 button-hover-color-transition focus:bg-green-700 focus:shadow-[0_0_0_1px_white,0_0_0_3px_theme(colors.green.400)]',
        className
      )}
    >
      <div className="text-label-2-semibold text-white">{payload}</div>
    </Link>
  );
}
