import dynamic from 'next/dynamic';
import Link from 'next/link';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { LogoSvg } from '../../assets/new';
import {
  AccountOutlineIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CrossIcon,
  MenuIcon,
  SearchIcon,
} from '../../assets/new/icons';
import type { HomePageProps } from '../../pages';
import type { RootState } from '../../store';
import type { ILinkProps } from '../../types/types';
import { cn } from '../../utils/cn';
import { GreenButtonLink } from '../button/green-button';
import { GreenOutlinedButton } from '../button/green-outlined-button';
import { GreenTransparentButtonLink } from '../button/green-transparent-button';
import { TransparentButtonLink } from '../button/transparent-button';
import AccountDropdown from '../dropdown/account-dropdown';
import type { NavDropDownModel } from '../dropdown/menuDropdown';
import NavDropdown from '../dropdown/menuDropdown';
import SearchPeopleModal from '../modals/search-people';

type IHeaderProps = {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  isLoggedIn: boolean;
};

type IMobileNavDropdown = {
  model: NavDropDownModel;
  className?: string;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
};

const Drawer = dynamic(() => import('react-modern-drawer'), {
  suspense: true,
});

const project = {
  label: 'Project',
  items: [
    {
      label: 'About us',
      url: '/what-is-the-population-project',
    },
    {
      label: 'Methodology',
      url: '/methodology',
    },
    {
      label: 'Privacy Policy',
      url: '/privacy',
    },
    {
      label: 'Blog',
      url: '/blog',
    },
    {
      label: "Founder's thoughts",
      url: '/founders-thoughts',
    },
  ],
};

const resources = {
  label: 'Contributing',
  items: [
    {
      label: 'Help us',
      url: '/guides/volunteering',
    },
    {
      label: 'Submit a list',
      url: '/volunteer/submit',
    },
    {
      label: 'Create a record',
      url: '/create-human',
    },
  ],
};

export default function Header({
  countries,
}: Pick<HomePageProps, 'countries'>) {
  const { isLoggedIn } = useSelector((state: RootState) => state.user);
  const [isOpenModal, setOpenModal] = useState(false);

  return (
    <div className="relative shadow-[0px_6px_10px_-4px_rgba(12,39,36,0.08)]">
      <div className="container">
        <DesktopHeader isLoggedIn={isLoggedIn} setOpenModal={setOpenModal} />
        <MobileHeader isLoggedIn={isLoggedIn} setOpenModal={setOpenModal} />
        <SearchPeopleModal
          isOpen={isOpenModal}
          setOpen={setOpenModal}
          countries={countries}
        />
      </div>
    </div>
  );
}

const DesktopHeader = ({ isLoggedIn, setOpenModal }: IHeaderProps) => (
  <div className={cn('flex h-20 items-center max-xl:hidden')}>
    <Link href={'/'}>
      <LogoSvg height="3.25rem" className="mr-[3.25rem]" />
    </Link>
    <div className="flex w-full items-center justify-between max-lg:hidden">
      <Menu />
      <div className="flex items-center">
        <GreenOutlinedButton
          payload="Search a record"
          Icon={<SearchIcon width="1.25rem" height="1.25rem" />}
          onClick={() => setOpenModal(true)}
          className="mr-6 rounded-[2rem] pl-4 pr-8"
        />
        {isLoggedIn ? <AuthorizedSection /> : <UnauthorizedSection />}
      </div>
    </div>
  </div>
);

function Menu() {
  return (
    <div className={cn('flex flex-row items-center justify-between space-x-2')}>
      <NavDropdown model={project} />
      <NavDropdown model={resources} />
      <TransparentButtonLink href="/world-map" payload={'World Map'} />
      <TransparentButtonLink href="/world" payload={'Countries'} />
    </div>
  );
}

const AuthorizedSection = () => <AccountDropdown />;

const UnauthorizedSection = () => (
  <div className="flex flex-row items-center">
    <TransparentButtonLink
      href="/sign-in"
      payload={'Log in'}
      IconBefore={<AccountOutlineIcon width="1.25rem" height="1.25rem" />}
      className="mr-2"
    />
    <GreenButtonLink payload="Get started" href={'/sign-up'} />
  </div>
);

const MobileHeader = ({ isLoggedIn, setOpenModal }: IHeaderProps) => {
  const [isOpenMenu, setOpenMenu] = useState(false);

  return (
    <div className={cn('hidden h-[72px] items-center max-xl:flex')}>
      <button
        onClick={() => setOpenMenu(true)}
        className="mr-5 hidden max-xl:inline-block"
      >
        <MenuIcon height="1.5rem" width="1.5rem" className="fill-neutral-600" />
      </button>
      <Link href="/">
        <LogoSvg height="44px" />
      </Link>

      <button
        className="ml-auto flex size-11 items-center justify-center rounded-[32px] border border-green-600"
        onClick={() => setOpenModal(true)}
      >
        <SearchIcon width="20px" height="20px" className="fill-green-600" />
      </button>

      <MobileMenu
        isOpenMenu={isOpenMenu}
        setOpenMenu={setOpenMenu}
        resources={resources}
        project={project}
        isLoggedIn={isLoggedIn}
      />
    </div>
  );
};

const MobileMenu = ({
  project,
  resources,
  isOpenMenu,
  setOpenMenu,
  isLoggedIn,
}: {
  project: NavDropDownModel;
  resources: NavDropDownModel;
  isOpenMenu: boolean;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
  isLoggedIn: boolean;
}) => (
  <Drawer
    className={cn(
      'flex flex-col px-3 pb-8 pt-6 max-h-[100svh] gap-5 overflow-y-scroll'
    )}
    lockBackgroundScroll
    style={{
      boxShadow: isOpenMenu
        ? '4px 0px 10px -4px rgba(12, 39, 36, 0.08)'
        : 'none',
    }}
    direction="left"
    size="20rem"
    open={isOpenMenu}
    overlayColor="rgba(12, 39, 36, 0.7)"
    onClose={() => setOpenMenu(false)}
  >
    <button onClick={() => setOpenMenu(false)}>
      <CrossIcon width="1.5rem" height="1.5rem" className="fill-neutral-500" />
    </button>

    <MobileNavDropdown
      setOpenMenu={setOpenMenu}
      model={project}
      className="mt-1"
    />
    <MobileNavDropdown setOpenMenu={setOpenMenu} model={resources} />

    <MobileNavLink
      onClick={() => setOpenMenu(false)}
      href="/world"
      payload={'Countries'}
      className="ml-8"
    />
    <MobileNavLink
      onClick={() => setOpenMenu(false)}
      href={isLoggedIn ? '/account' : '/sign-in'}
      payload={'Account'}
      className="ml-8"
    />
    <MobileNavLink
      onClick={() => setOpenMenu(false)}
      href="/world-map"
      payload={'World Map'}
      className="ml-8"
    />

    <GreenButtonLink
      onClick={() => setOpenMenu(false)}
      payload="Get started"
      href={'/sign-up'}
      className="mt-auto justify-self-end text-center"
    />
  </Drawer>
);

const MobileNavDropdown = ({
  model,
  className,
  setOpenMenu,
}: IMobileNavDropdown) => (
  <div className={cn(className)}>
    <div className="flex">
      <ChevronDownIcon
        width="1.5rem"
        height="1.5rem"
        className="mr-2 fill-neutral-400"
      />
      <div className="text-title-2-semibold text-neutral-950">
        {model.label}
      </div>
    </div>
    <div className="ml-8 mt-3 flex flex-col items-start space-y-3">
      {model.items.map(({ label, url }, key) => (
        <GreenTransparentButtonLink
          onClick={() => setOpenMenu(false)}
          href={url}
          payload={label}
          key={key}
        />
      ))}
    </div>
  </div>
);

const MobileNavLink = ({
  payload,
  className,
  ...props
}: { payload: string } & ILinkProps) => (
  <Link
    {...props}
    className={cn('flex justify-between items-center', className)}
  >
    <div className="text-title-2-semibold text-neutral-950">{payload}</div>
    <ChevronRightIcon
      width="1.5rem"
      height="1.5rem"
      className="mr-2 fill-green-500"
    />
  </Link>
);
