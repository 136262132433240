import { Dialog, Transition } from '@headlessui/react';
import _ from 'lodash';
import moment from 'moment';
import Link from 'next/link';
import React, {
  Fragment,
  type Dispatch,
  type SetStateAction,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { CrossIcon, LineChartIcon, UpdateIcon } from '../../assets/new/icons';
import type { HomePageProps } from '../../pages';
import type { RootState } from '../../store';
import type { DataWorldChartTypes } from '../../types/types';
import type { DefaultNrOfResultsEnum } from '../../types/user';
import { deleteCookie, getCookie } from '../../utils/cookies';
import { formatInteger } from '../../utils/format';
import { listOfPopularSearches } from '../../utils/listOfPopularSearches';
import { GreenTransparentButton } from '../button/green-transparent-button';
import { TransparentButton } from '../button/transparent-button';
import { SearchComponent } from '../search';

type Props = {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
} & Pick<HomePageProps, 'countries'>;

type RecentsResponse = {
  firstName: string;
  lastName: string;
  country: string;
  results: number;
};

type PopularResponse = typeof listOfPopularSearches[0]['searches'][0] & {
  count: number;
};

export default function SearchPeopleModal({
  isOpen,
  setOpen,
  countries,
}: Props) {
  const { defaultNrOfResults } = useSelector((state: RootState) => state.user);

  const recentSearchesString = getCookie('recentSearches') as
    | string
    | undefined;

  const [recentSearches, setRecentSearches] = useState<
    RecentsResponse[] | undefined
  >(recentSearchesString ? JSON.parse(recentSearchesString) : undefined);

  const clearRecents = () => {
    deleteCookie('recentSearches');
    setRecentSearches(undefined);
  };

  const popularSearches = _.map(listOfPopularSearches, 'searches')
    .flat()
    .splice(Math.round(moment().date() / 2), 5)
    .map((item, index) => ({
      ...item,
      count: _.random((5 - index) * 10_000, (5 - index + 1) * 10_000),
    }));

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 overflow-hidden bg-[rgba(12,39,36,0.65)] transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 m-auto mt-[3.25rem] size-fit rounded-lg bg-white shadow-xl transition-all max-sm:m-0 max-sm:w-full max-sm:rounded-none">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="flex flex-col overflow-hidden p-6 max-sm:h-dvh max-sm:px-5 max-sm:pb-8 max-sm:pt-3">
              <div className="mb-6 flex items-center justify-between max-sm:mb-3">
                <div className="text-title-2-semibold text-neutral-950">
                  Search a Human
                </div>
                <TransparentButton
                  onClick={() => setOpen(false)}
                  IconBefore={<CrossIcon width="1.25rem" height="1.25rem" />}
                  className="p-1.5"
                />
              </div>
              <SearchComponent
                countries={countries}
                className="mb-6 shadow-[0px_4px_16px_-5px_rgba(12,39,36,0.12)] max-sm:mb-4"
                buttonPayload={'Search'}
                onClickSearch={() => setOpen(false)}
                uniqueId="search-people"
                focusOnMount
              />

              {recentSearches?.length ? (
                <div className="flex-auto overflow-y-auto">
                  <div className="mb-1 flex justify-between pl-4">
                    <div className="flex items-center">
                      <UpdateIcon
                        width="1.25rem"
                        height="1.25rem"
                        className="mr-2 fill-neutral-500"
                      />
                      <div className="py-2.5 text-label-2-semibold text-neutral-500">
                        Recents
                      </div>
                    </div>
                    <GreenTransparentButton
                      payload={'Clear history'}
                      className="px-4 py-2.5"
                      onClick={clearRecents}
                    />
                  </div>
                  <Recents
                    className="mb-2 max-sm:mb-1"
                    results={recentSearches}
                    defaultNrOfResults={defaultNrOfResults}
                    countries={countries}
                    onClickLink={() => setOpen(false)}
                  />

                  {/* Removed due to POP-1937
                <div className="mb-1 flex items-center pl-4">
                  <LineChartIcon
                    width="1.25rem"
                    height="1.25rem"
                    className="mr-2 fill-neutral-500"
                  />
                  <div className="py-2.5 text-label-2-semibold text-neutral-500">
                    Popular this Week
                  </div>
                </div>
                <Popular
                  results={popularSearches}
                  defaultNrOfResults={defaultNrOfResults}
                  countries={countries}
                  onClickLink={() => setOpen(false)}
                /> */}
                </div>
              ) : null}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const Recents = ({
  className,
  results,
  defaultNrOfResults,
  countries,
  onClickLink,
}: {
  className?: string;
  results?: RecentsResponse[];
  defaultNrOfResults: DefaultNrOfResultsEnum;
  countries: DataWorldChartTypes[];
  onClickLink: () => void;
}) => (
  <div className={className}>
    {results?.map(({ firstName, lastName, country, results }, key) => (
      <Link
        href={{
          pathname: '/search',
          query: {
            firstName: firstName,
            lastName: lastName,
            country: countries.find((option) => option?.name === country)
              ?.iso_2,
            page: '1',
            pageSize: defaultNrOfResults,
          },
        }}
        onClick={onClickLink}
        key={key}
        className="flex justify-between rounded-lg px-4 py-2.5 *:text-label-2-medium hover:bg-gray-100"
      >
        <div className="text-neutral-950">
          {firstName} {lastName} <span className="text-neutral-500">in</span>{' '}
          {country}
        </div>
        <div className="text-neutral-500 max-sm:hidden">
          {formatInteger(results ?? 0)} result
          {results > 1 ? 's' : ''}
        </div>
      </Link>
    ))}
  </div>
);

const Popular = ({
  results,
  defaultNrOfResults,
  countries,
  onClickLink,
}: {
  results: PopularResponse[];
  defaultNrOfResults: DefaultNrOfResultsEnum;
  countries: DataWorldChartTypes[];
  onClickLink: () => void;
}) => {
  return (
    <div>
      {results.map((result, key) => {
        const queryObject: {
          firstName?: string;
          lastName?: string;
          country?: string;
          page?: string;
          pageSize?: string;
          birthDay?: string;
          birthMonth?: string;
          birthYear?: string;
          id?: string;
          alive?: string;
        } = {
          firstName: result.firstName,
          lastName: result.lastName,
          country: countries.find((option) => option?.iso_3 === result.country)
            ?.iso_2,
          page: '1',
          pageSize: defaultNrOfResults,
          alive: 'both',
        };

        if (result?.birth_day) {
          queryObject['birthDay'] = result?.birth_day?.toString();
        }

        if (result?.birth_month) {
          queryObject['birthMonth'] = result?.birth_month?.toString();
        }

        if (result?.birth_year) {
          queryObject['birthYear'] = result?.birth_year?.toString();
        }

        if (result?.id) {
          queryObject['id'] = result?.id?.toString();
        }

        return (
          <Link
            href={{
              pathname: '/search',
              query: queryObject,
            }}
            key={key}
            onClick={onClickLink}
            className="flex justify-between rounded-lg px-4 py-2.5 *:text-label-2-medium hover:bg-gray-100"
          >
            <div className="text-neutral-950">
              {result.firstName} {result.lastName}{' '}
              <span className="text-neutral-500">in</span> {result.country}
            </div>
            <div className="text-neutral-500 max-sm:hidden">
              {result.count} search{result.count > 1 ? 'es' : ''}
            </div>
          </Link>
        );
      })}
    </div>
  );
};
