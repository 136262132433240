export { default as AccountOutlineIcon } from './account-outline.svg';
export { default as ArrowLeftIcon } from './arrow-left.svg';
export { default as ArrowRightIcon } from './arrow-right.svg';
export { default as BookmarkMultipleOutlineIcon } from './bookmark-multiple-outline.svg';
export { default as ChevronDownIcon } from './chevron-down.svg';
export { default as ChevronLeftIcon } from './chevron-left.svg';
export { default as ChevronRightIcon } from './chevron-right.svg';
export { default as ChevronUpIcon } from './chevron-up.svg';
export { default as CrossIcon } from './cross.svg';
export { default as ElipsIcon } from './elips.svg';
export { default as FacebookIcon } from './facebook.svg';
export { default as GlobeSimpleIcon } from './globe-simple.svg';
export { default as InstagramIcon } from './instagram.svg';
export { default as LineChartIcon } from './line-chart.svg';
export { default as LinkedinIcon } from './linkedin.svg';
export { default as LogoutIcon } from './logout.svg';
export { default as MapMarkerIcon } from './map-marker.svg';
export { default as MenuIcon } from './menu.svg';
export { default as PencilIcon } from './pencil.svg';
export { default as SearchIcon } from './search.svg';
export { default as SquareEditOutlineIcon } from './square-edit-outline.svg';
export { default as TextBoxOutlineIcon } from './text-box-outline.svg';
export { default as XSocialIcon } from './x-social.svg';
export { default as UpdateIcon } from './update.svg';
