import { Logo } from '../brand/logo.component';
import { SimpleLogo } from '../brand/simple-logo.component';

export function LogoNavbar() {
  return (
    <nav className="flex h-20 w-full items-center justify-center border-b border-zinc-100">
      <SimpleLogo
        hasLink
        size={'small-mediu'}
        responsive
        className="mx-auto flex size-11 items-center justify-center md:hidden"
      />
      <Logo
        hasLink
        size={'medium'}
        responsive
        className="hidden h-[52px] w-[146px] items-center justify-center md:flex"
      />
    </nav>
  );
}
