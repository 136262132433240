import Link from 'next/link';
import React, { useMemo } from 'react';

interface SecondaryButtonProps {
  basicTextClassName?: string;
  textClassName: string;
  basicButtonClassName?: string;
  extraButtonClassName?: string;
  children?: JSX.Element;
  text?: string;
  width?: number;
  height?: number;
  disabled?: boolean;
  onClick?: (
    e?: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
  widthFull?: boolean;
  href?: string;
  spinner?: JSX.Element | boolean;
  leftIcon?: boolean;
  title?: string;
}

export const SecondaryButton = ({
  textClassName,
  basicTextClassName = 'text-center justify-center text-[#226357] text-sm font-medium',
  basicButtonClassName = 'flex justify-center rounded-md hover:bg-[#F2FFFC] disabled:opacity-[0.3] border border-[#34a28a] items-center',
  extraButtonClassName = '',
  children,
  width,
  height,
  disabled = false,
  spinner = undefined,
  text,
  title,
  onClick,
  widthFull = false,
  leftIcon = false,
  href,
}: SecondaryButtonProps) => {
  const props = useMemo(() => {
    return {
      disabled,
      onClick,
      className: `${basicButtonClassName} ${extraButtonClassName} ${
        widthFull ? 'w-full' : width ? `w-[${width}px]` : '' // todo this needs to be replaced, it will be purged on build
      } ${height ? `h-[${height}px]` : ''}`, // todo this needs to be replaced, it will be purged on build. Replace with explicit Tailwind classname, such as h-[44px] when using this component
    };
  }, [
    basicButtonClassName,
    disabled,
    extraButtonClassName,
    height,
    href,
    onClick,
    width,
    widthFull,
    title,
  ]);

  if (href) {
    return (
      <Link prefetch={false} href={href} {...props} title={title}>
        {leftIcon ? children : null}
        <p className={`${basicTextClassName} ${textClassName}`}>{text}</p>
        {!leftIcon ? children : null}
      </Link>
    );
  }

  return (
    <button {...props}>
      {!spinner ? (
        <>
          {leftIcon ? children : null}
          <p className={`${basicTextClassName} ${textClassName}`}>{text}</p>
          {!leftIcon ? children : null}
        </>
      ) : (
        <>{spinner}</>
      )}
    </button>
  );
};
