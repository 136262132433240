import { clsx } from 'clsx';
import Link from 'next/link';
import React from 'react';
import type { ILinkProps } from '../../types/types';

type Options = {
  payload?: string;
  IconBefore?: JSX.Element;
  IconAfter?: JSX.Element;
};

export function GreenTransparentButton({
  payload,
  IconBefore,
  IconAfter,
  className,
  ...props
}: Options & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'>) {
  return (
    <button
      className={clsx(
        'flex items-center justify-center rounded-md',
        'text-green-600 hover:text-green-800 focus:text-green-800 disabled:text-neutral-400',
        'fill-green-600 hover:fill-green-800 focus:fill-green-800 disabled:fill-neutral-400',
        className
      )}
      {...props}
    >
      <Children
        payload={payload}
        IconBefore={IconBefore}
        IconAfter={IconAfter}
      />
    </button>
  );
}

export function GreenTransparentButtonLink({
  payload,
  IconBefore,
  IconAfter,
  className,
  ...props
}: Options & ILinkProps) {
  return (
    <Link
      {...props}
      className={clsx(
        'flex items-center justify-center rounded-md disabled:pointer-events-none',
        'text-green-600 hover:text-green-800 focus:text-green-800 disabled:text-neutral-400',
        'fill-green-600 hover:fill-green-800 focus:fill-green-800 disabled:fill-neutral-400',
        className
      )}
    >
      <Children
        IconBefore={IconBefore}
        IconAfter={IconAfter}
        payload={payload}
      />
    </Link>
  );
}

const Children = ({
  IconBefore,
  IconAfter,
  payload,
}: Pick<Options, 'IconBefore' | 'payload' | 'IconAfter'>) => (
  <div className={clsx('flex items-center')}>
    {IconBefore}
    {payload && (
      <div
        className={clsx(
          'text-label-2-medium',
          IconBefore && 'ml-1',
          IconAfter && 'mr-1'
        )}
      >
        {payload}
      </div>
    )}
    {IconAfter}
  </div>
);
