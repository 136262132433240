/* eslint-disable @next/next/no-script-component-in-head */
import '../styles/global.css';
import 'react-modern-drawer/dist/index.css';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { animated, useSpring } from '@react-spring/web';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { checkUserToken, getUserServerSide } from 'src/actions/userActions';
import { LogoNavbar } from 'src/components/nav/logoNavbar.component';
import { nextUrl } from 'src/config';
import { DefaultNrOfResultsEnum } from 'src/types/user';
import { deleteCookie, getCookie, setCookie } from 'src/utils/cookies';
import { displayToast } from 'src/utils/displayToast';
import { AppContext } from '../AppContext';
import ErrorIcon from '../assets/ErrorIcon';
import { ActionSheet } from '../components/action-sheet/action-sheet';
import { TransparentButtonLink } from '../components/button/transparent-button';
import { AppNavbar } from '../components/nav/appNavbar.component';
import Header from '../components/nav/header.component';
import { PrimaryButton } from '../components/primary-button/primary-button';
import { SecondaryButton } from '../components/secondary-button/secondary-button';
import { homepageActions } from '../reducers/homepageReducer';
import { userActions } from '../reducers/userReducers';
import type { RootState } from '../store';
import { wrapper } from '../store';
import type { OptionTypes } from '../types/actionSheetTypes';
import { cn } from '../utils/cn';
import EventEmitter from '../utils/EventEmitter';

const client = new EventEmitter();

function App({ Component, pageProps }: AppProps) {
  const { isMegaMenuOpen } = useSelector((state: RootState) => state.search);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch();

  const userAcceptedCookie = getCookie('userCookies');

  const [isCookiePopupOpen, setIsCookiePopupOpen] = useState<boolean>();

  useEffect(() => {
    if ((userAcceptedCookie as string) === 'accepted') {
      setIsCookiePopupOpen(false);
    } else {
      setIsCookiePopupOpen(true);
    }
  }, [userAcceptedCookie]);

  useEffect(() => {
    if (isCookiePopupOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isCookiePopupOpen]);

  const onClickAccept = () => {
    setIsCookiePopupOpen(false);

    setCookie('userCookies', 'accepted', 43200);
  };

  const router = useRouter();

  const setCookieInformationForPrivacyPage = () => {
    setIsCookiePopupOpen(false);
    dispatch(
      userActions.setIsCommingFromLearnMore({ isCommingFromLearnMore: true })
    );
  };

  const isOnPrivacyPage = router?.pathname === '/privacy';

  // Get user based on user_token
  const getUserBasedOnToken = () => {
    const userToken = getCookie('userToken');

    if (userToken && userToken?.toString()?.length > 0) {
      dispatch(userActions.setIsUserLoggedIn({ isLoggedIn: true }));
      dispatch(
        userActions.setEditCreateOption({
          editCreateOption: 'dissmissed-as-user',
        })
      );
      // Check if user token is valid
      checkUserToken(userToken as string)
        .then((res) => {
          if (res?.status === 'invalid') {
            displayToast(
              'Your session has expired. Please log in again.',
              'error',
              <ErrorIcon />
            );
            deleteCookie('userToken');
            deleteCookie('user');
            deleteCookie('rememberMe');
            dispatch(userActions.setIsUserLoggedIn({ isLoggedIn: false }));
            dispatch(
              userActions.setEditCreateOption({ editCreateOption: null })
            );
            dispatch(userActions.setUserName({ userName: null }));
            dispatch(
              userActions.setEmailConfirmed({ isEmailConfirmed: false })
            );
            dispatch(userActions.setCreatedAt({ createdAt: null }));
            dispatch(userActions.setUserCountry({ userCountry: null }));
          }
          if (res?.status === 'success') {
            getUserServerSide(userToken as string).then((res) => {
              const user = res;
              dispatch(userActions.setUserId({ userId: user?.id }));
              dispatch(userActions.setUserName({ userName: user?.username }));
              dispatch(
                userActions.setDefaultNrOfResults({
                  defaultNrOfResults:
                    DefaultNrOfResultsEnum[
                      user?.defaultNrOfResults as unknown as keyof typeof DefaultNrOfResultsEnum
                    ] || DefaultNrOfResultsEnum.TEN,
                })
              );
              if (!user?.email?.includes('@thepopulationproject.org')) {
                dispatch(userActions.setUserEmail({ email: user?.email }));
                dispatch(
                  userActions.setEmailConfirmed({ isEmailConfirmed: true })
                );
              } else {
                dispatch(userActions.setUserEmail({ email: null }));
                dispatch(
                  userActions.setEmailConfirmed({ isEmailConfirmed: false })
                );
              }

              if (user?.country?.length > 0) {
                dispatch(
                  userActions.setUserCountry({ userCountry: user?.country })
                );
              }

              dispatch(
                userActions.setCreatedAt({ createdAt: user?.createdAt })
              );
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const rememberMeCookie = getCookie('rememberMe');

  useEffect(() => {
    if (rememberMeCookie?.toString() === 'yes') {
      getUserBasedOnToken();
    }
  }, [rememberMeCookie]);

  const styleForHomapgeContainer = useSpring({
    opacity: isMegaMenuOpen ? 0.35 : 0,
  });

  useEffect(() => {
    // We need this to disable matomo to check on dev.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (nextUrl === 'https://thepopulationproject.org/api') {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-var
        var _mtm = (window._mtm = window._mtm || []);
        _mtm.push({
          'mtm.startTime': new Date().getTime(),
          event: 'mtm.Start',
        });
        const d = document,
          g = d.createElement('script'),
          s = d.getElementsByTagName('script')[0];

        g.async = true;
        g.src =
          'https://cdn.matomo.cloud/thepopulationproject.matomo.cloud/container_LPF3K9xC.js';

        const gtmScriptInit = d.createElement('script'),
          gtmScript = d.getElementsByTagName('script')[0];

        gtmScriptInit.async = true;
        gtmScriptInit.setAttribute('id', 'gtm-script');
        gtmScriptInit.setAttribute('strategy', 'afterInteractive');
        gtmScriptInit.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WL639LL')`;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        gtmScript.parentNode.insertBefore(gtmScriptInit, gtmScript);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        s.parentNode.insertBefore(g, s);
      }, 100);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setTimeout(() => {
      const tourCookie = getCookie('tour');
      const secondTourCookie = getCookie('secondTour');
      if (
        (document.referrer === '' ||
          document.referrer.startsWith(window.location.origin)) === false
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const _paq = window._paq || [];
        _paq.push([
          'AbTesting::create',
          {
            name: 'Homepage-Animations',
            percentage: 100,
            includedTargets: [
              {
                attribute: 'url',
                inverted: '0',
                type: 'equals_simple',
                value: 'https://thepopulationproject.org/',
              },
            ],
            excludedTargets: [],
            startDateTime: '2023/10/14 00:00:00 UTC',
            endDateTime: '2023/10/20 23:59:59 UTC',

            variations: [
              {
                name: 'original',
                // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
                activate: function (event: any) {
                  // nothing needs to be done here
                },
              },
              {
                name: 'Dorian',
                activate: function (event: {
                  onReady: (arg0: () => void) => void;
                }) {
                  event.onReady(() => {
                    if (typeof tourCookie === 'undefined') {
                      setCookie('tour', JSON.stringify(false), 60 * 60 * 24);
                    }
                    dispatch(homepageActions?.setDorianVariant(true));
                  });
                },
              },
              {
                name: 'Ionut',
                activate: function (event: {
                  onReady: (arg0: () => void) => void;
                }) {
                  event.onReady(() => {
                    if (typeof secondTourCookie === 'undefined') {
                      setCookie('secondTour', 'true', 60 * 60 * 24);
                    }
                    dispatch(homepageActions?.setIonutVariant(true));
                  });
                },
              },
            ],
            trigger: function () {
              return true; // here you can further customize which of your visitors will participate in this experiment
            },
          },
        ]);
      }
    }, 400);
  }, []);

  const [menuOption, setMenuOption] = useState<OptionTypes>('');

  const handleOption = (option: OptionTypes) => () => {
    setMenuOption(option);
  };

  const newDesignUrls = [
    '^/$',
    '^/founders-thoughts$',
    '^/founders-thoughts/.+$',
    '^/blog$',
    '^/blog/.+$',
    '^/world.*$',
    '^/country/.+$',
  ];

  const getNavbar = () => {
    // if router.pathname is /sign-in or sign up or set new password or continue as guest or forgot password, we don't want to show the navbar
    if (
      router.pathname === '/sign-in' ||
      router.pathname === '/sign-up' ||
      router.pathname === '/set-new-password' ||
      router.pathname === '/continue-as-guest' ||
      router.pathname === '/forgot-password'
    ) {
      return <LogoNavbar />;
    }

    /**
     * new design
     * NOTE: THIS IS TEMPORARY
     * **/
    if (newDesignUrls.some((url) => new RegExp(url).test(router.pathname))) {
      return <Header countries={pageProps.countries} />;
    }

    return <Header countries={pageProps.countries} />;
  };

  const showActionSheet = useMemo(() => {
    /** new design **/
    if (newDesignUrls.some((url) => new RegExp(url).test(router.pathname))) {
      return false;
    }

    if (
      router.pathname === '/sign-in' ||
      router.pathname === '/sign-up' ||
      router.pathname === '/set-new-password' ||
      router.pathname === '/continue-as-guest' ||
      router.pathname === '/forgot-password'
    ) {
      return false;
    }
    return true;
  }, [router.pathname]);

  return (
    <AppContext.Provider value={{ client: client }}>
      <GoogleOAuthProvider
        clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || ''}
      >
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, user-scalable=1, minimum-scale=1, maximum-scale=5"
          />
          <meta
            name="viewport"
            content="initial-scale=1.0001,viewport-fit=cover,user-scalable=1,maximum-scale=1"
          />
          {process.env.NODE_ENV !== 'development' && (
            <meta
              name="google-site-verification"
              content="vHEbbGBINsj52K51GVBxu6Hxxi7RUhl62OSf8PqiStI"
            />
          )}
        </Head>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <animated.div
          className={`fixed z-10 size-full bg-black ${
            isMegaMenuOpen ? '' : 'hidden'
          }`}
          style={styleForHomapgeContainer}
        />
        <NextNProgress
          color="#409F85"
          options={{
            showSpinner: false,
            minimum: 0.1,
          }}
          showOnShallow={false}
          stopDelayMs={0}
        />
        {pageProps.countries && getNavbar()}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Component {...pageProps} />
        {showActionSheet && (
          <ActionSheet
            countries={pageProps.countries}
            option={menuOption}
            handleOption={handleOption}
          />
        )}
        {/* COOKIE POPUP */}
        {!isOnPrivacyPage && (
          <div
            className={cn(
              'fixed top-0 z-[999] flex justify-center items-center w-full h-full flex-col shadow-[0_-1px_20px_rgba(0,0,0,.11)] transition-opacity opacity-0 bg-[#0C2724A6]',
              isCookiePopupOpen === undefined && 'opacity-0',
              !isCookiePopupOpen && 'hidden',
              isCookiePopupOpen && 'opacity-100'
            )}
          >
            <div className="mx-5 rounded-xl bg-white p-6 md:w-[500px]">
              <p className="pb-4 text-lg font-semibold">We use cookies</p>
              <p className="pb-6 text-sm font-normal">
                This action require writing some functional cookies to your
                browser. These cookies allow a website to remember choices that
                have made in the past for better experience.
              </p>
              <div className="flex w-full justify-end gap-2">
                <SecondaryButton
                  extraButtonClassName="w-full md:w-fit"
                  onClick={setCookieInformationForPrivacyPage}
                  href="/privacy"
                  textClassName="px-4"
                  height={44}
                  text="Learn more"
                />
                <PrimaryButton
                  extraButtonClassName="w-full md:w-fit"
                  onClick={onClickAccept}
                  textClassName="px-4"
                  height={44}
                  text="Accept cookies"
                />
              </div>
            </div>
          </div>
        )}
      </GoogleOAuthProvider>
    </AppContext.Provider>
  );
}

export default appWithTranslation(wrapper.withRedux(App));
