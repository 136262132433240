import { Menu, Transition } from '@headlessui/react';
import Link from 'next/link';
import { Fragment } from 'react';
import { cn } from 'src/utils/cn';
import { ChevronDownIcon } from '../../assets/new/icons';
import { TransparentButton } from '../button/transparent-button';

export type NavDropDownModel = {
  label: string;
  items: { url: string; label: string }[];
};

type Options = {
  model: NavDropDownModel;
};

export default function NavDropdown({ model }: Options) {
  return (
    <Menu as="div" className="font-Montserrat relative">
      <Menu.Button as="div">
        <TransparentButton
          IconAfter={<ChevronDownIcon width="1rem" height="1rem" />}
          payload={model.label}
        />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 origin-top-right rounded-md bg-white p-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div>
            {model.items.map(({ label, url }, key) => (
              <Menu.Item key={key}>
                {({ active }) => (
                  <Link
                    prefetch={false}
                    href={url}
                    className={cn(
                      active ? 'bg-neutral-100' : '',
                      'block rounded-md px-4 py-2 min-w-[11.5rem]'
                    )}
                  >
                    <span className="text-label-2-regular text-neutral-950">
                      {label}
                    </span>
                  </Link>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
