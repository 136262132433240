import { Menu, Transition } from '@headlessui/react';
import { deleteCookie } from 'cookies-next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from 'src/reducers/userReducers';
import type { RootState } from 'src/store';
import { cn } from 'src/utils/cn';
import {
  AccountOutlineIcon,
  BookmarkMultipleOutlineIcon,
  ChevronDownIcon,
  LogoutIcon,
  SquareEditOutlineIcon,
} from '../../assets/new/icons';

export default function AccountDropdown() {
  const { userName, email } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const router = useRouter();

  const logout = () => {
    deleteCookie('userToken');
    deleteCookie('user');
    deleteCookie('rememberMe');
    deleteCookie('isSigneWithApple');
    dispatch(userActions.setIsUserLoggedIn({ isLoggedIn: false }));
    dispatch(userActions.setEditCreateOption({ editCreateOption: null }));
    dispatch(userActions.setUserName({ userName: null }));
    dispatch(userActions.setEmailConfirmed({ isEmailConfirmed: false }));
    dispatch(userActions.setCreatedAt({ createdAt: null }));
    dispatch(userActions.setUserCountry({ userCountry: null }));

    router?.push(router.asPath);
  };

  return (
    <Menu as="div" className="font-Montserrat relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center justify-center rounded-md px-4 py-3 hover:bg-green-100 focus:bg-green-100 focus:shadow-[0_0_0_1px_white,0_0_0_3px_theme(colors.green.400)]">
          <AccountOutlineIcon
            width="1.25rem"
            height="1.25rem"
            className="mr-1 fill-neutral-500"
          />
          <span className="mr-1 text-label-2-medium text-neutral-700">
            Account
          </span>
          <ChevronDownIcon
            width="1rem"
            height="1rem"
            className="fill-neutral-500"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cn(
            'absolute right-0 z-10 mt-2 w-56 origin-top-right text-nowrap rounded-lg bg-white text-sm  focus:outline-none p-2',
            'shadow-[0px_20px_40px_-8px_rgba(15,25,40,0.08),0px_0px_14px_-4px_rgba(0,0,0,0.10)]',
            'border border-neutral-100'
          )}
        >
          <Menu.Item>
            {({ active }) => (
              <div
                className={cn(
                  'py-1 px-[0.875rem] flex text-ellipsis flex-col mb-2'
                )}
              >
                <span className="overflow-hidden text-ellipsis text-label-2-semibold text-neutral-950">
                  {userName}
                </span>
                <span className="mt-1 h-fit w-full overflow-hidden text-ellipsis text-label-3-regular text-neutral-500">
                  {email}
                </span>
              </div>
            )}
          </Menu.Item>
          <div className="border-y py-2 *:px-3.5 *:py-3">
            <Menu.Item>
              {({ active }) => (
                <Link
                  prefetch={false}
                  href="/account"
                  className={cn(
                    active ? 'bg-neutral-100' : '',
                    'flex gap-2 items-center'
                  )}
                >
                  <AccountOutlineIcon
                    width="1.25rem"
                    height="1.25rem"
                    className="fill-neutral-500"
                  />
                  <span className="text-label-2-regular text-neutral-950">
                    Profile
                  </span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  prefetch={false}
                  href={'/account?option=my-records'}
                  className={cn(
                    active ? 'bg-neutral-100' : '',
                    'flex gap-2 items-center'
                  )}
                >
                  <BookmarkMultipleOutlineIcon
                    width="1.25rem"
                    height="1.25rem"
                    className="fill-neutral-500"
                  />
                  <span className="text-label-2-regular text-neutral-950">
                    My records
                  </span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  prefetch={false}
                  href={'/account?option=edit-history'}
                  className={cn(
                    active ? 'bg-neutral-100' : '',
                    'flex gap-2 items-center'
                  )}
                >
                  <SquareEditOutlineIcon
                    width="1.25rem"
                    height="1.25rem"
                    className="fill-neutral-500"
                  />
                  <span className="text-label-2-regular text-neutral-950">
                    Edit History
                  </span>
                </Link>
              )}
            </Menu.Item>
          </div>
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={logout}
                className={cn(
                  active ? 'bg-neutral-100' : '',
                  'py-3 px-[0.875rem] rounded-b-lg mt-2 w-full flex items-center gap-2'
                )}
              >
                <LogoutIcon
                  width="1.25rem"
                  height="1.25rem"
                  className="fill-neutral-500"
                />
                <span className="text-label-2-regular text-neutral-950">
                  Log out
                </span>
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
