import { Form, Formik } from 'formik';
import router from 'next/router';
import type {
  DropdownPassThroughMethodOptions,
  DropdownProps,
} from 'primereact/dropdown';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import React, {
  type Dispatch,
  forwardRef,
  type SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useSelector } from 'react-redux';
import { cn } from 'src/utils/cn';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CrossIcon,
  GlobeSimpleIcon,
  SearchIcon,
} from '../../assets/new/icons';
import type { HomePageProps } from '../../pages';
import type { RootState } from '../../store';

type Props = Pick<HomePageProps, 'countries'> & {
  className?: string;
  buttonPayload: string;
  onClickSearch?: () => void;
  uniqueId: string;
  focusOnMount?: boolean;
};

type ButtonProps = Pick<Props, 'buttonPayload'> & {
  innerRef?: React.RefObject<HTMLButtonElement>;
};

type SearchInputOptions = {
  payload: string;
  onChange: (value: string) => void;
  uniqueId?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'children' | 'onChange'>;

type CountryObject = { code: string; name: string };

type SearchSelectOptions = {
  countries: CountryObject[];
  setSelectedCountry: Dispatch<SetStateAction<CountryObject | undefined>>;
  selectedCountry?: CountryObject;
  uniqueId?: string;
  dropdownId?: string;
};

export function SearchComponent(props: Props) {
  const { defaultNrOfResults } = useSelector((state: RootState) => state.user);

  const [selectedCountry, setSelectedCountry] = useState<
    CountryObject | undefined
  >();
  const countrySelectRef = useRef<HTMLInputElement>(null);

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [firstName, setFirstName] = useState('');
  const firstNameInputRef = useRef<HTMLInputElement>(null);

  const [lastName, setLastName] = useState('');
  const lastNameInputRef = useRef<HTMLInputElement>(null);

  const countries = props.countries.map((country) => ({
    code: country.iso_2,
    name: country.name,
  }));

  useEffect(() => {
    if (props.focusOnMount) {
      firstNameInputRef.current && firstNameInputRef.current.focus();
    }
  }, [props.focusOnMount]);

  const onSelectedCountry: Dispatch<
    SetStateAction<CountryObject | undefined>
  > = (selectedCountry: SetStateAction<CountryObject | undefined>) => {
    setSelectedCountry(selectedCountry);
    setTimeout(() => {
      if (
        submitButtonRef?.current &&
        submitButtonRef?.current !== document.activeElement
      ) {
        submitButtonRef?.current?.focus();
      }
    }, 100);
  };

  const search = ({
    firstName,
    lastName,
  }: {
    firstName: string;
    lastName: string;
  }) => {
    console.log({ firstName, lastName, selectedCountry });
    if (!firstName) {
      firstNameInputRef.current && firstNameInputRef.current.focus();
      return;
    }

    if (!lastName) {
      lastNameInputRef.current && lastNameInputRef.current.focus();
      return;
    }

    if (!selectedCountry) {
      countrySelectRef.current && countrySelectRef.current.focus();
      return;
    }

    if (props.onClickSearch) props.onClickSearch();

    router.push({
      pathname: '/search',
      query: {
        firstName,
        lastName,
        country: selectedCountry.code,
        page: 1,
        pageSize: defaultNrOfResults,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
      }}
      onSubmit={search}
    >
      {({ values, handleChange }) => (
        <Form
          className={cn(
            'mb-8 flex flex-row rounded-lg border bg-white max-sm:flex-col max-sm:w-full',
            props.className
          )}
        >
          <SearchInput
            ref={firstNameInputRef}
            payload="First name"
            placeholder="Enter first name"
            value={values.firstName}
            onChange={handleChange('firstName')}
            uniqueId={props.uniqueId}
          />
          <div className="my-auto hidden h-[4.35rem] w-[0.05rem] bg-gray-200 md:block" />
          <SearchInput
            ref={lastNameInputRef}
            payload="Last name"
            placeholder="Enter last name"
            value={values.lastName}
            onChange={handleChange('lastName')}
            uniqueId={props.uniqueId}
          />
          <SearchSelect
            ref={countrySelectRef}
            countries={countries}
            selectedCountry={selectedCountry}
            setSelectedCountry={onSelectedCountry}
            uniqueId={props.uniqueId}
          />
          <SearchButton
            type="submit"
            buttonPayload={props.buttonPayload}
            innerRef={submitButtonRef}
          />
        </Form>
      )}
    </Formik>
  );
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputOptions>(
  ({ payload, onChange, uniqueId, ...inputProps }, ref) => (
    <div className="m-2 flex flex-col justify-center rounded-lg bg-white focus-within:bg-neutral-100 hover:bg-neutral-100 sm:w-36 md:w-48 lg:w-56 xl:w-64">
      <label
        htmlFor={`input-${payload}-${uniqueId}`}
        className="mb-1 px-3 pb-0 pt-2 text-label-1-semibold text-green-950"
      >
        {payload}
      </label>
      <div className="flex cursor-pointer items-center">
        <input
          {...inputProps}
          ref={ref}
          id={`input-${payload}-${uniqueId}`}
          type="text"
          style={{
            outline: 'none',
            WebkitAppearance: 'none',
            boxShadow: 'none',
          }}
          onChange={(e) => onChange(e.target.value)}
          className="mb-2 w-full border-0 bg-inherit px-3 py-0 text-label-1-semibold text-green-600 placeholder:text-label-1-regular placeholder:text-neutral-500"
        />
        <button
          type="button"
          onClick={() => onChange('')}
          tabIndex={-1}
          className={`${!inputProps.value ? 'hidden' : ''}`}
        >
          <CrossIcon
            width="1.25rem"
            height="1.25rem"
            className="fill-neutral-400"
          />
        </button>
      </div>
    </div>
  )
);

const SearchSelect = forwardRef<HTMLInputElement, SearchSelectOptions>(
  ({ countries, setSelectedCountry, selectedCountry, uniqueId }, ref) => (
    <div className="my-2 border-l border-neutral-200 ">
      <div className="mx-1 flex flex-col justify-center rounded-lg bg-white focus-within:bg-neutral-100 hover:bg-neutral-100 sm:w-36 md:w-48 lg:w-56 xl:w-64">
        <label
          className="mb-1 px-3 pb-0 pt-2 text-label-1-semibold text-green-950"
          htmlFor={`input-Country-${uniqueId}`}
        >
          Country
        </label>
        <CountryDropDown
          ref={ref}
          countries={countries}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          dropdownId={`input-Country-${uniqueId}`}
        />
      </div>
    </div>
  )
);

const CountryDropDown = forwardRef<HTMLInputElement, SearchSelectOptions>(
  ({ countries, selectedCountry, setSelectedCountry, dropdownId }, ref) => {
    const Tailwind = {
      root: () => ({
        className: classNames(
          'bg-inherit w-full inline-flex relative select-none'
        ),
      }),
      input: () => ({
        className: classNames(
          'cursor-pointer block flex flex-auto align-items-center items-center overflow-hidden overflow-ellipsis whitespace-nowrap relative',
          'text-label-1-regular text-neutral-500',
          'transition duration-200 bg-transparent rounded appearance-none',
          'focus:outline-none focus:shadow-none'
        ),
      }),
      trigger: () => ({
        className: classNames('flex items-center justify-center shrink-0'),
      }),
      wrapper: {
        className: classNames(
          'min-h-[16.75rem] overflow-auto overflow-y-auto p-2',
          '[&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300'
        ),
      },
      header: {
        className: classNames('p-2'),
      },
      panel: {
        className: classNames(
          'min-h-[16.75rem] mt-5 rounded-lg  bg-white sm:w-36 md:w-48 lg:w-56 xl:w-64 overflow-hidden',
          'border-0 shadow-lg'
        ),
      },
      item: ({ context }: DropdownPassThroughMethodOptions) => ({
        className: classNames(
          'text-label-2-regular text-neutral-950 cursor-pointer overflow-hidden relative',
          'px-3 py-2 mx-0 border-0 rounded-md focus:border-0 hover:border-0',
          'hover:shadow-none focus:shadow-none focus:outline-none hover:outline-none',
          {
            'bg-neutral-100 text-label-2-semibold': context.selected,
            'bg-neutral-100': context.focused,
          }
        ),
      }),
      filterContainer: ({ context }: DropdownPassThroughMethodOptions) => ({
        className: classNames(
          'w-full flex flex-row items-center px-3',
          'border border-green-600 rounded-full overflow-hidden'
        ),
      }),
      filterInput: () => ({
        className: 'w-full px-3 !border-0 !shadow-none !ring-0 !outline-none',
      }),
      filterIcon: () => ({
        className: 'text-green-600',
      }),
    };

    return (
      <div className="card justify-content-center flex">
        <Dropdown
          inputId={dropdownId}
          pt={Tailwind}
          filter
          filterBy="name"
          filterInputAutoFocus
          filterPlaceholder="Search country"
          unstyled={true}
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.value)}
          options={countries}
          optionLabel="country"
          focusInputRef={ref}
          showClear
          showOnFocus
          placeholder="Select a Country"
          valueTemplate={selectedCountryTemplate}
          itemTemplate={countryOptionTemplate}
          className="w-full px-3 pb-2 pt-0"
          dropdownIcon={(opts) => {
            return (
              <ChevronDownIcon
                {...opts.iconProps}
                width="1.25rem"
                height="1.25rem"
                className="fill-neutral-400"
              />
            );
          }}
          collapseIcon={(opts) => {
            return (
              <ChevronUpIcon
                {...opts.iconProps}
                width="1.25rem"
                height="1.25rem"
                className="fill-neutral-400"
              />
            );
          }}
          clearIcon={(opts) => {
            return (
              <CrossIcon
                {...opts.iconProps}
                tabIndex={-1}
                width="1.25rem"
                height="1.25rem"
                className="mr-1 cursor-pointer self-center fill-neutral-400 focus:shadow-none focus:outline-none"
              />
            );
          }}
        />
      </div>
    );
  }
);

const selectedCountryTemplate = (
  option: SearchSelectOptions['selectedCountry'],
  props: DropdownProps
) => {
  if (option) {
    return (
      <div className="align-items-center flex">
        <div className="text-label-1-semibold text-green-600">
          {option.name}
        </div>
      </div>
    );
  }

  return <span>{props.placeholder}</span>;
};

const countryOptionTemplate = (option: CountryObject) => {
  const iconStyle = {
    width: '1.25rem',
    height: '1.25rem',
    display: 'flex',
  };

  return (
    <div className="align-items-center flex items-center">
      <div className="mr-2">
        {option.code === 'WO' ? (
          <GlobeSimpleIcon {...iconStyle} className="fill-neutral-500" />
        ) : (
          <ReactCountryFlag
            alt={`Flag of ${option.code.toLowerCase()}`}
            countryCode={option.code.toLowerCase()}
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            style={iconStyle}
            className="rounded-[1.25rem]"
            title={option.code.toLowerCase()}
          />
        )}
      </div>
      <div>{option.name}</div>
    </div>
  );
};

const SearchButton = ({
  buttonPayload,
  innerRef,
  ...props
}: ButtonProps &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'>) => {
  return (
    <button
      {...props}
      ref={innerRef}
      className="button-hover-color-transition flex flex-row items-center justify-center bg-green-600 py-4 pl-4 pr-5 hover:bg-green-700 focus:bg-green-700 max-sm:rounded-b-lg sm:rounded-r-lg"
    >
      <SearchIcon className="mr-2 fill-white" width="1.5rem" height="1.5rem" />
      <span className="text-label-1-semibold text-white">{buttonPayload}</span>
    </button>
  );
};
