import Link from 'next/link';
import React from 'react';
import type { ILinkProps } from '../../types/types';
import { cn } from '../../utils/cn';

type Options = {
  payload?: string;
  Icon?: JSX.Element;
};

export function GreenOutlinedButton({
  payload,
  Icon,
  className,
  ...props
}: Options & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'>) {
  return (
    <button
      className={cn(
        `group rounded-md border border-green-500 py-3 hover:bg-green-50`,
        'focus:bg-green-50 focus:shadow-[0_0_0_1px_white,0_0_0_3px_theme(colors.green.400)]',
        '[&>div>div]:focus:text-green-700',
        Icon ? 'pl-3' : 'pl-4',
        !payload ? 'pr-3' : 'pr-4',
        className
      )}
      {...props}
    >
      <Children Icon={Icon} payload={payload} />
    </button>
  );
}

export function GreenOutlinedButtonLink({
  Icon,
  payload,
  className,
  ...props
}: Options & ILinkProps) {
  return (
    <Link
      {...props}
      className={cn(
        `rounded-md border border-green-500 py-3  hover:bg-green-50`,
        'focus:bg-green-50 focus:shadow-[0_0_0_1px_white,0_0_0_3px_theme(colors.green.400)]',
        Icon ? 'pl-3' : 'pl-4',
        !payload ? 'pr-3' : 'pr-4',
        className
      )}
    >
      <Children Icon={Icon} payload={payload} />
    </Link>
  );
}

const Children = ({ Icon, payload }: Pick<Options, 'Icon' | 'payload'>) => (
  <div
    className={cn(
      'flex items-center [&>svg]:fill-green-600',
      payload && '[&>svg]:mr-1'
    )}
  >
    {Icon}
    {payload && (
      <div className="text-label-2-medium text-green-600 max-sm:text-label-3-medium">
        {payload}
      </div>
    )}
  </div>
);
