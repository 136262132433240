import Link from 'next/link';
import React, { useMemo } from 'react';

interface PrimaryButtonProps {
  basicTextClassName?: string;
  textClassName: string;
  basicButtonClassName?: string;
  extraButtonClassName?: string;
  text?: string;
  children?: JSX.Element;
  width?: number | string;
  height?: number | string;
  disabled?: boolean;
  onClick?: (
    e?: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  spinner?: JSX.Element | boolean;
  widthFull?: boolean;
  id?: string;
  href?: string;
  type?: string;
  title?: string;
}

export const PrimaryButton = ({
  textClassName,
  basicTextClassName = 'text-white text-sm font-semibold',
  basicButtonClassName = 'flex justify-center disabled:opacity-[0.3] disabled:bg-gray-500 hover:bg-[#006857] transition items-center rounded-[4px] bg-[#257c6b]',
  extraButtonClassName = '',
  width,
  height,
  children,
  disabled = false,
  text,
  spinner = undefined,
  widthFull = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
  id,
  href,
  title,
}: PrimaryButtonProps) => {
  const props = useMemo(() => {
    return {
      id,
      disabled,
      onClick,
      onMouseEnter,
      onMouseLeave,
      className: `${basicButtonClassName} ${extraButtonClassName} ${
        widthFull ? 'w-full' : width ? `w-[${width?.toString()}px]` : '' // todo this needs to be replaced, it will be purged on build
      }  ${height ? `h-[${height?.toString()}px]` : ''}  `, // todo this needs to be replaced, it will be purged on build. Replace with explicit Tailwind classname, such as h-[44px] when using this component
    };
  }, [
    basicButtonClassName,
    disabled,
    extraButtonClassName,
    height,
    href,
    id,
    onClick,
    width,
    widthFull,
    onMouseEnter,
    onMouseLeave,
    title,
  ]);

  if (href) {
    return (
      <Link prefetch={false} href={href} {...props} title={title}>
        {children}
        <p className={`${basicTextClassName} ${textClassName}`}>{text}</p>
      </Link>
    );
  }

  return (
    <button {...props}>
      {!spinner ? (
        <>
          {children}
          <p className={`${basicTextClassName} ${textClassName}`}>{text}</p>
        </>
      ) : (
        <>{spinner}</>
      )}
    </button>
  );
};
